import $api from "@/api"
const requsetPath = {
  "bidding": $api.bidding.whertherJumpDetail,
  "library": $api.library.whetherJumpDetail
}
/**
 * @description
 * @author yuk_yao
 * @date 2021-09-22
 * @export
 * @param {import("vuex").Store} store
 * @param {import("vue-router").Router} router
 * @param {String} type 标识当前类型 bidding or library
 * @param {String} linkPath 待跳转连接
 * @param {Object} item 数据源
 * @param {Function} reloadFn 如当前处于文库or招投标详情,直接请求详情数据
 * @returns {*} 
 */
export default async (store, router, type, linkPath, {
  price,
  id,
  title
}, reloadFn = null) => {
  const showFormType = new Map([
    [ ["1"], () => store.commit("toggleLoginForm", true)],
    [ ["2", "4", "6"], () => store.commit("toggleOrdinaryForm", true)],
  ])
  const [err, data] = await requsetPath[type](id)
  if (err && err.toString().includes("401")) {
    store.commit("toggleLoginForm", true)
    return;
  }
  if (data.succeeded) {
    if (reloadFn) {
      reloadFn(id)
    } else {
      router.push({
        name: linkPath,
        query: {
          id
        }
      })
    }
  } else {
    let tips = ""
    switch (data.errors) {
      case "2":
        // 普通用户查看付费
        tips = "成为会员"
        break;
      case "4":
        // 高级会员查看付费信息(可查看条数已用完)
        tips = "升级超级会员"
        break;
      case "6":
        // 超级会员查看付费信息(可查看条数已用完)

      default:
        tips = null;
        break;
    }
    store.commit("setOrderInfo", {
      title,
      id,
      price,
      type: type === "bidding" ? 2 : 1,
      tips
    })
    const [[key, fn]] = [...showFormType].filter(([key, fn]) => key.includes(data.errors))
    fn()
  }
}
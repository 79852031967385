<template>
  <div class="inner_banner">
    <a class="db" href="javascript:void(0);"
      ><img :src="require('@/assets/images/ztb_banner.png')"
    /></a>
  </div>
  <main class="ztb_main policy_main">
    <div class="ztb_box">
      <class-items :classifies="classifies" @callback="getLibraries">
        <div>
          <span
            class="free dib"
            :class="[queryParams.Charge === '1' ? 'bg-gold' : 'bg-ccc']"
            @click="getLibraries('', queryParams.Title, '1')"
            >免费</span
          >
          <span
            class="pay dib"
            :class="[queryParams.Charge === '2' ? 'bg-gold' : 'bg-ccc']"
            @click="getLibraries('', queryParams.Title, '2')"
            >收费</span
          >
        </div>
      </class-items>
      <ul class="pl_list library-list">
        <li
          v-for="item in libraries.list"
          :key="item.id"
          class="library-item"
          @click="showDetail(store, router, 'library', 'LibraryDetail', item)"
        >
          <a href="javascript:void(0);" class="db w100p item">
            <div class="img_box">
              <img :src="require('@/assets/images/p_img.png')" class="db" />
            </div>
            <div class="brief w100p">
              <div class="title">{{ item.title }}</div>
              <div class="intro" v-html="item.abstracts"></div>
              <div class="date_price cf">
                <div class="date fl" v-formatTime>{{ item.createdTime }}</div>
                <div class="free fr" v-if="item.price === 0">免费</div>
                <div v-else>
                  <div class="num fr tc">
                    <i class="iconfont icon-crown"></i>
                    <span>{{ item.price }}元</span>
                  </div>
                  <div class="special fr tc mr10">
                    <i class="iconfont icon-crown"></i>
                    <span>会员专享</span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>

      <div class="pagnation">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.PageIndex"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryParams.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="libraries.totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <div class="ztb_latest">
      <div class="sidebar_box">
        <div class="title">
          <span>政策推荐</span>
        </div>
        <ul>
          <li v-for="item in topList" :key="item.id">
            <a
              herf="javascript:void(0);"
              class="recomm-item"
              @click="
                showDetail(store, router, 'library', 'LibraryDetail', item)
              "
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useInfoTree from "@/composable/universal/useInfoTree";
import usePagination from "@/composable/universal/usePagination";
import useAllLibrary from "@/composable/library/useAllLibrary";
import useLibraryTop from "@/composable/library/useLibraryTop";
import showDetail from "@/composable/universal/showDetail";
import ClassItems from "@/components/classItems";

const store = useStore();
const router = useRouter();

const { classifies } = useInfoTree("2");
const { queryParams, libraries, getLibraries } = useAllLibrary(classifies);
const { handleSizeChange, handleCurrentChange } = usePagination( queryParams, getLibraries);
const { topList } = useLibraryTop();
</script>
<style lang="less" scoped>
.library-list {
  padding: 0 20px 0 30px;
}
.library-item {
  padding: 35px 0 30px;
}
.pagnation {
  text-align: center;
}
.search-isfree {
  display: flex;
  align-items: baseline;
}
.search-isfree > div:last-child {
  flex-grow: 1;
  text-align: end;
}
.bg-gold {
  color: white !important;
  background: #cc9736 !important;
}
.bg-ccc {
  background: #ccc !important;
}
.pay {
  color: black;
}
.pay:hover {
  color: #cc9736;
}
.recomm-item {
  cursor: pointer;
}
</style>

import {onMounted, ref} from "vue"
import $api from "@/api"
export default function useLibraryTop(){
    const topList=ref([])
    const getTopList=async ()=>{
        const [err,data]=await $api.article.getPoliciesTop5({Number:5,isHot:2})
        if(!data.succeeded) return;
        topList.value=data.data;
    }
    onMounted(getTopList)
    return {
        topList
    }
}
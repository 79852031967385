import {onMounted, reactive, ref} from "vue"
import $api from "@/api"
export default function useAllLibrary(classItems){
 
    const libraries=reactive({
        list:[],
        totalCount:0
    })
    const queryParams=reactive({
        Title:"",
        TypeId:"-1",
        Charge:"0",
        PageIndex:1,
        PageSize:15
    })
    const getLibraries=async(id="",keywords="",charge="")=>{
        if(charge===queryParams.Charge){
            queryParams.Charge="0"
        }else if(!!charge){
            queryParams.Charge=charge
        }
        if (!!id) {
            queryParams.TypeId = id
            classItems.value.map(e => {
                e.value === id ? e.active = true : e.active = false;
                return e
            })
        }
        queryParams.Title=keywords
        const [err,data]=await $api.library.getLibraries(queryParams)
        if(!data.succeeded) return;
        libraries.list=data.data.items.sort((a,b)=>new Date(b.createdTime)-new Date(a.createdTime))
        libraries.totalCount=data.data.totalCount
    }
   
    onMounted(getLibraries)
    return {
        queryParams,libraries,getLibraries,
    }
}